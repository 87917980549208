@import '../../assets/sass/app.scss';

.mainRow {
  padding: 20px;
  margin-right: 5px !important;
}

.cards {
  height: 100% !important;
}

.specialCard > div {
  padding-top: 0px !important;
  padding-top: 5px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.sendItem {
  margin-top: -5px !important;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: right;
  @include for-md-down {
    font-size: 1.4rem !important;
  }
  @include for-s-down {
    font-size: 1.2rem !important;
  }
}

.subtitle {
  font-size: medium;
  text-align: right;
  @include for-s-down {
    font-size: small !important;
  }
}

.assets {
  font-size: medium;
  text-align: center;
  @include for-s-down {
    font-size: small !important;
  }
}

.icon {
  font-size: xxx-large;
  @include for-md-down {
    font-size: xx-large !important;
  }
}

.splitMinutesButtom {
  margin-top: 8px;
}
