.ant-float-btn {
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.7);
  border-radius: 50%;

  &:active,
  &:focus,
  &:hover {
    box-shadow: none; // Elimina la sombra cuando el botón está activo, enfocado o cuando se pasa el ratón por encima
  }

  // Si el botón tiene hijos o pseudo-elementos que afectan la sombra:
  & > *,
  &::before,
  &::after {
    box-shadow: none; // Elimina cualquier sombra de los elementos hijos o pseudo-elementos
  }
}
