@import '../../assets/sass/app.scss';

.planner {
  padding: 1.2rem;
  height: auto;
  margin-bottom: 35px;

  .title {
    @include for-sm-down {
      margin-top: 0px !important;
    }
    @include for-xs-down {
      font-size: 14px;
      margin-top: 0px !important;
    }
  }

  .subtitle {
    @include for-sm-down {
      margin-top: 0px !important;
    }
    @include for-xs-down {
      font-size: 14px;
      margin-top: 0px !important;
    }
  }

  .low_plan {
    color: $high-alert1 !important;
  }

  .middle_plan {
    color: $medium-alert1 !important;
  }

  .high_plan {
    color: $ok-alert !important;
  }

  .collapse_light {
    background-color: $light-grey2 !important;
    margin-top: 10px;
  }

  .collapse_dark {
    background-color: $dark-grey2 !important;
    margin-top: 10px;
  }
}

.button__border {
  border: 1px solid $l-primary-color;
}

.questionUpdatePlan {
  margin: 0;
  margin-top: 0;
  text-align: end;
  @include for-sm-down {
    text-align: justify;
  }
}
