$l-primary-color: #25abe8;
$d-primary-color: #033c6c;

$dark: #191818;
$error: #f63123;
$ok: #26c061;

$white: #ffffff;

$light-grey1: #f0f3f7;
$light-grey2: #e2e5e9;
$light-grey3: #d7dbdf;
$light-grey4: #c5cbd1;
$light-grey5: #a1abb6;

$dark-grey0: #0b1327;
$dark-grey1: #1c1c1c;
$dark-grey2: #232335;
$dark-grey3: #343646;
$dark-grey4: #363e52;
$dark-grey5: #364662;
$dark-grey6: #4b5e7e;
$dark-grey7: #5c6f8f;

$light-blue1: #e8f0fe;

$high-alert: #770c05;
$high-alert1: #ff3324;
$medium-alert: #ff9d00b3;
$medium-alert1: #ff9300;
$low-alert: #0596fdb3;
$low-alert1: #0596fd;
$ok-alert: #1c771fb3;
$none-alert: #8488a0;
$onTrack-alert: #3a31f1;
$disable-alert: #9f9f9f;
$border-color: #e0e0e0;
$light-body-background: #ffffff;
$dark-body-background: #151525;
$backgroundForm: rgba(0, 0, 0, 0.5);
$boxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$unitWhite: #f2f2f2;
$backgroundTransparent: 'transparent';

:export {
  l-primary-color: $l-primary-color;
  d-primary-color: $d-primary-color;

  l-bg-color: $light-body-background;
  d-bg-color: $dark-body-background;

  l-i-bg-color: $light-blue1;
  d-i-bg-color: $dark-grey2;

  l-i-text-color: $dark;
  d-i-text-color: $dark-grey3;

  l-t-text-color: $dark-grey4;
  d-t-text-color: $light-grey4;

  l-st-text-color: $dark-grey5;
  d-st-text-color: $light-grey5;

  l-error-color: $high-alert1;
  d-error-color: $high-alert;

  l-calendar-bg-color: $light-grey2;
  d-calendar-bg-color: $dark-grey4;

  l-text-color: $white;
  d-text-color: $dark-grey1;

  ok: $ok;
  ok-alert: $ok-alert;
  medium-alert1: $medium-alert1;
  high-alert: $high-alert;
  high-alert1: $high-alert1;
}
