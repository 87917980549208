@import '../../assets/sass/app.scss';

.ql-save::before {
  content: '\1F4BE';
  font-size: 14px;
  vertical-align: middle;
}

.ql-loading::before {
  content: '\23F3';
  font-size: 14px;
  vertical-align: middle;
}

.ql-toolbar {
  button:hover,
  button:focus,
  button.ql-active,
  .ql-picker-label:hover,
  .ql-picker-label.ql-active,
  .ql-picker-item:hover,
  .ql-picker-item.ql-selected {
    color: $l-primary-color;
  }
}

.ql-editor {
  height: 150px !important;
}

/*
.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active,
.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-item.ql-selected,
.ql-toolbar button:hover,
.ql-toolbar button:focus,
.ql-toolbar button.ql-active {
  background-color: tuColorDeFondoDeseado; 
}
*/
