@import '../../assets/sass/app.scss';

.Popover {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__content {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: 8px;

    &__list {
      height: 200px;
      overflow-y: auto;

      &__item {
        padding: 0 !important;

        button {
          padding-right: 8px;
          padding-left: 8px;

          &:disabled {
            cursor: pointer;
          }
        }
      }
    }
  }
}
