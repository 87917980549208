@import '../../../assets/sass/app.scss';
/* Ejemplo de CSS para ocultar elementos específicos */
.rbc-event-label {
  display: none;
}
/*
.event-duration {
  margin-top: 0px !important;
}

.rbc-event {
  @include for-lg-down {
    width: auto !important;
    height: 19px !important;
    margin-top: 4px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;
    justify-content: center;
    .rbc-event-content {
      font-size: 0.8rem !important;
    }
  }

  @include for-md-down {
    width: auto !important;
    height: 18px !important;
    margin-top: 4px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  @include for-sm-down {
    width: auto !important;
    height: 16px !important;
    margin-top: 2px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 1px !important;

    .rbc-event-content {
      font-size: 0.8rem !important;
      margin-top: 1px !important;
    }
  }
  @include for-s-down {
    width: auto !important;
    height: 10px !important;
    margin-top: 1px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;

    .rbc-event-content {
      font-size: 0.6rem !important;
      margin-top: -2px !important;
    }
  }
  @include for-xs-down {
    width: auto !important;
    height: 10px !important;
    margin-top: 1px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 1px !important;

    .rbc-event-content {
      font-size: 0.55rem !important;
      margin-top: -2px !important;
    }
  }

  @include for-xs-ultralong-down {
    width: auto !important;
    height: 16px !important;
    margin-top: 2px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 1px !important;

    .rbc-event-content {
      font-size: 0.83rem !important;
      margin-top: -2px !important;
    }
  }

  @include for-xs-long-down {
    width: auto !important;
    height: 14px !important;
    margin-top: 2px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;

    .rbc-event-content {
      font-size: 0.78rem !important;
      margin-top: -2px !important;
    }
  }

  @include for-sm-down-landscape {
    width: auto !important;
    height: 17px !important;
    margin-top: 2px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;

    .rbc-event-content {
      font-size: 0.7rem !important;
      margin-top: 1px !important;
    }
  }
  @include for-s-down-landscape {
    width: auto !important;
    height: 10px !important;
    margin-top: 1px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;

    .rbc-event-content {
      font-size: 0.6rem !important;
      margin-top: -2px !important;
    }
  }
  @include for-xs-down-landscape {
    width: auto !important;
    height: 10px !important;
    margin-top: 1px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;

    .rbc-event-content {
      font-size: 0.55rem !important;
      margin-top: -2px !important;
    }
  }

  @include for-xs-ultralong-down-landscape {
    width: auto !important;
    height: 16px !important;
    margin-top: 2px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;

    .rbc-event-content {
      font-size: 0.83rem !important;
      margin-top: -2px !important;
    }
  }

  @include for-xs-long-down {
    width: auto !important;
    height: 14px !important;
    margin-top: 2px !important;
    display: flex !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    padding-top: 0px !important;

    .rbc-event-content {
      font-size: 0.78rem !important;
      margin-top: -2px !important;
    }
  }
}

.rbc-date-cell {
  @include for-md-down {
    margin-bottom: 2px;
    font-size: 0.75rem !important;
  }
}
*/
