@import "../../assets/sass/app.scss";

.spanContainer {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.spanContainer .item {
  flex-grow: 1;
  margin-right: 10px;
}

.spanContainer .item:last-child {
  margin-right: 0;
  align-self: center;
}
.modal {
  width: 60% !important;
  @include for-xxl-down {
    width: 30% !important;
  }

  @include for-xl-down {
    width: 40% !important;
  }
  @include for-lg-down {
    width: 50% !important;
  }
  @include for-sm-down {
    width: 85% !important;
  }

  @include for-s-down {
    width: 95% !important;
  }

  .switch {
    margin-top: 4px;
  }
}
:global {
  .ant-picker-time-panel-column {
    overflow-y: auto !important;
  }
}
