@import '../../assets/sass/app.scss';

.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: fixed;
  background-color: black;
  bottom: 0;
  z-index: 100;
}
