.swipe-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
}

.swipe-hint__hand {
  width: 90px;
  height: 90px;
  z-index: 500;
}

.swipe-hint__arrow {
  width: 90px;
  height: 90px;
  z-index: 500;
}

.swipe-hint-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con 50% de opacidad */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}