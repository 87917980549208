.install-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #25abe8;
  color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 7000;
}

.banner-logo {
  width: 48px;
  height: 48px;
  margin-right: 8px;
  border-radius: 10%;
  border: 2px solid #00ffff;
}

.banner-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.install-btn {
  background-color: #ffffff;
  color: #25abe8;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.close-btn {
  background-color: transparent;
  color: #ffffff;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}
