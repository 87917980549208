@import '../../assets/sass/app.scss';

.notebook {
  padding: 1.2rem;
  width: 100%;
  height: calc(100vh - 97px);
  overflow: auto;

  .no-notes-container {
    display: flex;
    justify-content: center;
  }

  .timeline-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  .comments-paragraph {
    white-space: pre-line;
  }

  .ant-collapse-header,
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .small-button {
    width: 18px !important;
    min-width: 18px !important;
    height: 18px;
    font-size: 10px;
    padding: 0;

    svg {
      font-size: 10px;
    }
  }

  .ant-picker {
    width: 200px;
    margin-bottom: 24px;
  }

  .ant-timeline-item-label {
    width: auto !important;
    line-height: 1.2;
    inset-block-start: -4px !important;
  }

  .ant-timeline-item-tail,
  .ant-timeline-item-head {
    inset-inline-start: 40px !important;
  }

  .ant-timeline-item-content {
    inset-inline-start: 50px !important;
    inset-block-start: -5px !important;
  }
}
