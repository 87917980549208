@import './assets/sass/app.scss';

.MainContainer {
  min-height: 100%;
  /*
  @include for-xxxl-down {
    min-height: 92vh !important;
  }

  @include for-xxl-down {
    min-height: 92vh !important;
  }

  @include for-xl-down {
    min-height: 94vh !important;
  }

  @include for-lg-down {
    min-height: 95vh !important;
  }

  @include for-xs-down {
    min-height: 155vh !important;
  }
*/
  &__wrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 1000;

    &__mock {
      background-color: $ok;
      color: $white;
      width: fit-content;
    }
  }
}
