@import '../../assets/sass/app.scss';

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  padding-top: calc(env(safe-area-inset-top, 0.5rem) + 0.3rem) !important;
  position: sticky;
  background-color: black;
  top: 0;
  z-index: 100;

  .rightSide {
    display: flex;

    .logoWrapper {
      img {
        height: 4vh;
        border-radius: 20%;
        border: 2px solid #00ffff;
      }
      @include for-md-down-landscape {
        img {
          margin-top: 5px;
          height: 8vh;
          border-radius: 20%;
          border: 2px solid #00ffff;
        }
      }
      @include for-sm-down-landscape {
        img {
          margin-top: 5px;
          height: 12vh;
          border-radius: 20%;
          border: 2px solid #00ffff;
        }
      }
      @include for-s-down {
        img {
          margin-top: 5px;
          height: 5vh;
          border-radius: 20%;
          border: 2px solid #00ffff;
        }
      }
    }

    .gretting {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 180px;

      @include for-sm-down {
        margin-left: 5px;
        margin-top: 10px;
      }

      @include for-s-down {
        margin-left: 5px;
      }

      @include for-xs-ultralong-down {
        margin-left: 5px;
        margin-top: 5px;
      }

      .welcome {
        font-size: 1rem;
        @include for-s-down {
          font-size: 0.75rem;
        }

        @include for-xs-ultralong-down {
          font-size: 1rem;
        }
      }
    }

    .person {
      margin-left: 0.5em;
      font-weight: bold;
      font-size: 0.75rem !important;
      text-decoration: none;
      text-transform: capitalize;
      @include for-s-down {
        font-size: 0.7rem !important;
        margin-left: 0em;
      }
      @include for-xs-ultralong-down {
        font-size: 1rem !important;
        margin-left: 0em;
      }
    }
  }

  .leftSide {
    display: flex;
    align-items: center;
    gap: 5px;
    @include for-s-down {
      gap: 1px;
    }
  }
}
