@import './app.scss';

.inputContainer {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}

.inputAndIconContainer {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  height: 55px;
  width: 65%;
}

.passwordIcon {
  position: absolute;
  top: 30%;
  right: 20px;
  cursor: pointer;
  color: $l-primary-color;
  font-size: 20px;
}

.roundedInput {
  border-radius: 25px;
  border-color: $l-primary-color !important;
  width: 100%;
  height: 55px;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  color: $l-primary-color !important;
  font-size: 15px;

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-box-shadow: 0 0 0 30px transparent inset !important;
      color: $l-primary-color !important;
    }

    // Placeholder estilos
    &::placeholder {
      // Webkit (Chrome, Safari, etc.)
      color: $l-primary-color !important;
    }

    &::-webkit-input-placeholder {
      // Webkit (Chrome, Safari, etc.)
      color: $l-primary-color !important;
    }

    &::-moz-placeholder {
      // Firefox
      color: $l-primary-color !important;
      opacity: 1; // Firefox reduce la opacidad del placeholder por defecto
    }

    &:-ms-input-placeholder {
      // Internet Explorer 10-11
      color: $l-primary-color !important;
    }

    &::-ms-input-placeholder {
      // Edge
      color: $l-primary-color !important;
    }
  }
}

.errorMessage {
  color: $high-alert1;
  font-size: 14px;
  margin-bottom: -100px;
}

.passwordInput {
  @extend .roundedInput;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
}
