@import '../../../assets/sass/app.scss';

.toolbarContainer {
  display: flex;
  gap: 1px;
  flex-direction: row;
  margin-left: 2px;
  .toolbar {
    display: flex;
    gap: 1px;
    margin-bottom: 4px;
  }
  .lastItem {
    margin-left: auto;
  }
}

.rootDrawer {
  top: 48px;

  @include for-sm-down {
    top: 58px;
  }

  @include for-s-down {
    top: 52px;
  }

  @include for-xs-down {
    top: 50px;
  }
  .menuItem {
    font-size: 1rem !important;
  }
}

.monthLabel {
  margin-top: 5px !important;
  margin-left: 0.5rem;
  text-transform: capitalize;
  text-align: center;

  @include for-xs-down {
    font-size: 18px !important;
  }
}
