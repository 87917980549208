@import './variables.scss';
@import './media-screen.scss';
@import './tour.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

#root > * {
  min-height: 100%;
  @include for-md-down {
    min-height: 100%;
  }
}

body {
  margin: 0px !important;
}

.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  margin-top: 50px;
  border-radius: 10%;
  /* Esto redondeará completamente la imagen. */
  border: 4px solid #00ffff;
  @include for-lg-down {
    margin-top: 170px;
  }
  @include for-md-down {
    margin-top: 120px;
  }
  @include for-s-down {
    margin-top: 80px;
  }
}

.buttonCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.roundedButton {
  border-radius: 20px;
  width: 50%;
  height: 40px;
}

.simpleCenterText {
  margin-top: 20px;
  text-align: center;
}

.forceHand {
  cursor: pointer !important;
  text-decoration: underline;
}

button {
  -webkit-tap-highlight-color: transparent;
}

/*
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0 30px red inset !important;
}
*/

::-webkit-input-placeholder {
  // Webkit (Chrome, Safari, etc.)
  color: $l-primary-color !important;
}

.timepickerClass {
  .anticon {
    color: $dark-grey5 !important;
  }

  & input {
    @include for-sm-down {
      font-size: 0.75rem !important;
    }
  }
}
