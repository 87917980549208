@import "../../assets/sass/app.scss";

.datePicker {
  width: 257px !important;
}
.modal {
  width: 20% !important;
  @include for-xxl-down {
    width: 25% !important;
  }

  @include for-xl-down {
    width: 30% !important;
  }
  @include for-lg-down {
    width: 35% !important;
  }
  @include for-sm-down {
    width: 85% !important;
  }

  @include for-s-down {
    width: 95% !important;
  }
}
:global {
  .ant-picker-time-panel-column {
    overflow-y: auto !important;
  }
}
