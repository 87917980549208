@import '../../assets/sass/app.scss';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/css/react-big-calendar.css';

.calendar_home_dark {
  position: relative;
  height: 600px;
  width: 95vw;
  margin: 3px 20px !important;
  border: 5px solid $dark-grey2;
  border-radius: 10px;

  @include for-xxxxl-down {
    height: 70vh;
    width: 97vw;
  }

  @include for-xxxl-down {
    height: 70vh;
    width: 96vw;
  }

  @include for-xxl-down {
    height: 70vh;
    width: 96vw;
  }

  @include for-xl-down {
    height: 70vh;
    width: 96vw;
  }

  @include for-lg-down {
    height: 70vh;
    width: 96vw;
  }

  @include for-md-down {
    height: 75vh;
    width: 95vw;
  }

  @include for-sm-down-landscape {
    height: 185vh !important;
    width: 90vw;
  }

  @include for-s-down-landscape {
    height: 190vh !important;
    width: 90vw;
  }

  @include for-xs-down-landscape {
    height: 195vh !important;
    width: 90vw;
  }

  @include for-xs-long-down-landscape {
    height: 190vh !important;
    width: 90vw;
  }

  @include for-xs-ultralong-down-landscape {
    height: 180vh !important;
    width: 90vw;
  }

  @include for-sm-down {
    height: 85vh;
    width: 90vw;
  }

  @include for-s-down {
    height: 90vh;
    width: 90vw;
  }

  @include for-xs-down {
    height: 95vh;
    width: 90vw;
  }

  @include for-xs-long-down {
    height: 90vh;
    width: 90vw;
  }

  @include for-xs-ultralong-down {
    height: 80vh;
    width: 90vw;
  }

  .rbc-calendar {
    background-color: $dark-grey2;
    color: $light-grey5;
  }

  /* Header de los días */
  .rbc-header {
    background-color: $dark-grey5;
    color: $light-grey1;
  }

  .rbc-off-range-bg {
    background: $dark-grey0;
  }

  .rbc-today {
    background: $dark-grey4;
  }

  .rbc-active {
    background-color: $dark-grey4 !important;
    color: $dark-grey1 !important;
  }

  .rbc-toolbar-label {
    color: $light-grey2;
  }

  .rbc-btn-group {
    button {
      color: $dark-grey7 !important;
    }
  }

  .rbc-date-cell {
    text-align: center;
  }
}

.calendar_home_light {
  position: relative;
  height: 600px;
  width: 95vw;
  border: 5px solid $light-grey1;
  border-radius: 10px;
  margin: 3px 20px !important;

  .rbc-calendar {
    background-color: $light-grey1;
    color: $dark-grey5;
  }

  @include for-xxxxl-down {
    height: 70vh;
    width: 97vw;
  }

  @include for-xxxl-down {
    height: 70vh;
    width: 97vw;
  }

  @include for-xxl-down {
    height: 70vh;
    width: 97vw;
  }

  @include for-xl-down {
    height: 70vh;
    width: 97vw;
  }

  @include for-lg-down {
    height: 70vh;
    width: 97vw;
  }

  @include for-md-down {
    height: 75vh;
    width: 95vw;
  }

  @include for-sm-down-landscape {
    height: 185vh !important;
    width: 95vw !important;
  }

  @include for-s-down-landscape {
    height: 190vh !important;
    width: 95vw !important;
  }

  @include for-xs-down-landscape {
    height: 195vh !important;
    width: 95vw !important;
  }

  @include for-xs-long-down-landscape {
    height: 190vh !important;
    width: 95vw !important;
  }

  @include for-xs-ultralong-down-landscape {
    height: 180vh !important;
    width: 95vw !important;
  }

  @include for-sm-down {
    height: 85vh;
    width: 90vw;
  }

  @include for-s-down {
    height: 90vh;
    width: 90vw;
  }

  @include for-xs-down {
    height: 95vh;
    width: 90vw;
  }

  @include for-xs-long-down {
    height: 90vh;
    width: 90vw;
  }

  @include for-xs-ultralong-down {
    height: 80vh;
    width: 90vw;
  }

  .rbc-today {
    background: $light-grey4;
  }
}

.calendar__swipe-hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Esto asegura que el SwipeHint esté encima del calendario */
}
