@import '../../assets/sass/app.scss';

.homeWP {
  margin-bottom: 25px;

  &__richContainer {
    padding: 10px 25px 10px 20px;

    &__richText {
      height: 200px;
      margin-bottom: 160px;
      color: $l-primary-color;

      @include for-xs-ultralong-down {
        padding-bottom: 275px !important;
      }
    }
  }
}

.ql-editor.ql-blank::before {
  color: $l-primary-color !important;
}
