@import '../../assets/sass/app.scss';
@import '../../assets/sass/inputs.scss';

.custom-select {
  height: 55px;
}

.custom-select {
  .ant-select-selector {
    height: 55px !important;
    line-height: 55px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 25px;
    border-color: #25abe8 !important;
    font-size: 15px;
  }
}

.custom-select-l {
  .ant-select-selector {
    background-color: $light-blue1 !important;
  }
}

.custom-select-d {
  .ant-select-selector {
    background-color: $dark-grey2 !important;
  }
}

.custom-select .ant-select-selector:-webkit-autofill,
.custom-select .ant-select-selector:-webkit-autofill:hover,
.custom-select .ant-select-selector:-webkit-autofill:focus,
.custom-select .ant-select-selector:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0 30px aqua inset !important;
}

.custom-select .ant-select-arrow {
  top: 60%;
  transform: translateY(-50%);
}

.custom-select .ant-select-selection-item {
  top: 30%;
}

.custom-select .ant-select-selection-placeholder {
  transform: translateY(20%);
  color: #25abe8;
}
