@import "../../assets/sass/inputs.scss";

.avatar {
  margin-bottom: 30px;
  margin-top: 50px;
}

.avatarBorder {
  border-width: 6px;
}

.accountBack {
  margin-top: 20px;
  margin-left: 20px;
}

.chevronLeftIcon {
  margin-left: 10px;
  margin-top: 50px;
  font-size: 20px;
}

.editAccountTitle {
  margin-left: 20px;
  font-weight: "bold";
  font-size: 20px;
}

.editButton {
  margin-bottom: 20px;
  margin-top: 20px;
}

.textDeleteAccount {
  margin-top: 10px;
  margin-bottom: 30px;
}
