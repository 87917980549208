@import "./media-screen.scss";

.ant-tour {
  @include for-xl-down {
    width: fit-content !important;
    padding-left: 15px !important;
  }
  @include for-lg-down {
    width: fit-content !important;
    padding-left: 15px !important;
  }
  @include for-md-down {
    width: 550px !important;
    padding-left: 15px !important;
  }
  @include for-sm-down {
    width: 300px !important;
    padding-left: 15px !important;
  }
  @include for-xs-down {
    width: 280px !important;
    padding-left: 15px !important;
  }
}
