$breakpoints: (
  'xs': 480px,
  's': 600px,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
  'xl': 1440px,
  'xxl': 1920px,
  'xxxl': 2560px,
  'xxxxl': 3280px,
);

@mixin for-xs-ultralong-down-landscape {
  @media (max-width: #{map-get($breakpoints, 'xs')}) and (min-height: 900px) and (orientation: landscape) {
    @content;
  }
}

@mixin for-xs-long-down-landscape {
  @media (max-width: #{map-get($breakpoints, 'xs')}) and (min-height: 700px) and (max-height: 900px) and (orientation: landscape) {
    @content;
  }
}

@mixin for-xs-down-landscape {
  @media (max-width: #{map-get($breakpoints, 'xs')}) and (orientation: landscape) {
    @content;
  }
}

@mixin for-s-down-landscape {
  @media (max-width: #{map-get($breakpoints, 's')}) and (orientation: landscape) {
    @content;
  }
}

@mixin for-sm-down-landscape {
  @media (max-width: #{map-get($breakpoints, 'sm')}) and (orientation: landscape) {
    @content;
  }
}

@mixin for-md-down-landscape {
  @media (max-width: #{map-get($breakpoints, 'md')}) and (orientation: landscape) {
    @content;
  }
}

@mixin for-xs-ultralong-down {
  @media (max-width: #{map-get($breakpoints, 'xs')}) and (min-height: 900px) {
    @content;
  }
}

@mixin for-xs-long-down {
  @media (max-width: #{map-get($breakpoints, 'xs')}) and (min-height: 700px) and (max-height: 900px) {
    @content;
  }
}

@mixin for-xs-down {
  @media (max-width: #{map-get($breakpoints, 'xs')}) {
    @content;
  }
}

@mixin for-s-down {
  @media (max-width: #{map-get($breakpoints, 's')}) {
    @content;
  }
}

@mixin for-sm-down {
  @media (max-width: #{map-get($breakpoints, 'sm')}) {
    @content;
  }
}

@mixin for-md-down {
  @media (max-width: #{map-get($breakpoints, 'md')}) {
    @content;
  }
}

@mixin for-lg-down {
  @media (max-width: #{map-get($breakpoints, 'lg')}) {
    @content;
  }
}

@mixin for-xl-down {
  @media (max-width: #{map-get($breakpoints, 'xl')}) {
    @content;
  }
}

@mixin for-xxl-down {
  @media (max-width: #{map-get($breakpoints, 'xxl')}) {
    @content;
  }
}

@mixin for-xxxl-down {
  @media (max-width: #{map-get($breakpoints, 'xxxl')}) {
    @content;
  }
}

@mixin for-xxxxl-down {
  @media (max-width: #{map-get($breakpoints, 'xxxxl')}) {
    @content;
  }
}
